import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Socket } from "socket.io-client";
import { Button, Avatar, Input } from "@nextui-org/react";
import { ComponentIcon, Maximize2, Minimize2 } from "lucide-react";
import { gql, useMutation, useQuery } from "@apollo/client";
import WordAttributionBar from "./WordAttributionBar";
import { ArrowUp2, ArrowLeft2 } from "iconsax-react";
import RoomTimerNew from "./RoomTimerNew";
import ProfileAvatar from "./ProfileAvatar";
import { useUserInfo } from "../hooks/useUserInfo";
import { getUnauthedUserHash } from "../utils/unauthedLocalStorage";
import { Tooltip } from "./primitives/Tooltip";
import { useInterval } from "../hooks/useInterval";
import InviteButton from "./InviteButton";
import PromptEditorNew from "./PromptEditorNew";
import { Message } from "../gql/graphql";
import ControlledRoomTimer from "./ControlledRoomTimer";

const GET_ROOM_DATA = gql`
  query getRoomDataFloatingDisplay($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      roomID
      prompt
      timeLeftSeconds
      name
      ownerID
      presetID
      promptHidden
      timeLeftSeconds
      endTime
      wordsWritten
      scheduledStartTime
      usernameWordList
      isGroup
      circle {
        circleID
        name
        imageDownloadURL
      }
    }
  }
`;

const CONVERT_ROOM_TO_GROUP_SESSION = gql`
  mutation ConvertRoomToGroupSession($roomID: String!) {
    convertRoomToGroupSession(request: { roomID: $roomID }) {
      success
    }
  }
`;

const GET_MESSAGES_FOR_ROOM = gql`
  query GetMessagesForRoomFooter($roomID: String!) {
    getMessagesForRoom(request: { roomID: $roomID }) {
      content
      messageID
      createdAt
      username
    }
  }
`;

const UPDATE_ROOM_TIMER = gql`
  mutation UpdateRoomTimer(
    $timeLeftSeconds: Int
    $endTime: DateTime
    $roomID: String!
  ) {
    updateRoomTimer(
      request: {
        timeLeftSeconds: $timeLeftSeconds
        endTime: $endTime
        roomID: $roomID
      }
    ) {
      success
    }
  }
`;

const COLOR_DURATION = 200;

export default function FloatingRoomDisplay({
  socket,
  roomID,
}: {
  socket: Socket | null;
  roomID?: string;
}) {
  const { userID, randomUsername, username, settled } = useUserInfo();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const [wordsWritten, setWordsWritten] = useState(0);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [timeLeftSeconds, setTimeLeftSeconds] = useState<number>(0);

  const { data: roomData, refetch } = useQuery(GET_ROOM_DATA, {
    variables: { roomID },
    skip: !roomID,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("Room data updated:", data);
      setWordsWritten(data.getRoomData.wordsWritten || 0);
    },
  });

  const [convertRoomToGroupSession] = useMutation(
    CONVERT_ROOM_TO_GROUP_SESSION,
    {
      onCompleted: async (data) => {
        console.log("Mutation completed:", data);
        if (data.convertRoomToGroupSession.success) {
          await refetch();
        }
      },
    }
  );

  const [messages, setMessages] = useState<Message[]>([]);
  const [messageInput, setMessageInput] = useState("");
  const [newChatMessage, setNewChatMessage] = useState(false);
  const scrollWindowRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [maxChatHeight, setMaxChatHeight] = useState(300);

  const [updateRoomTimer] = useMutation(UPDATE_ROOM_TIMER, {
    onCompleted: () => {
      socket?.emit("room_timer_update", {
        timeLeftSeconds,
        endTime: endTime?.toISOString(),
        roomID,
      });
    },
  });

  useQuery(GET_MESSAGES_FOR_ROOM, {
    variables: { roomID },
    onCompleted: (data) => {
      setMessages(data.getMessagesForRoom);
    },
    fetchPolicy: "no-cache",
  });

  const scrollToBottom = useCallback(() => {
    if (scrollWindowRef.current) {
      scrollWindowRef.current.scrollTop = scrollWindowRef.current.scrollHeight;
    }
  }, []);

  const sendMessage = useCallback(() => {
    if (socket && messageInput.trim()) {
      socket.emit("send_chat_message", {
        message: messageInput,
        roomID,
        userID,
      });
      setMessageInput("");
    }
  }, [messageInput, roomID, socket, userID]);

  useEffect(() => {
    if (socket) {
      if (!socket.hasListeners("new_message")) {
        socket.on("new_message", (...args) => {
          setMessages((prevMessages) => [...prevMessages, args[0]]);
          if (isChatExpanded) {
            setTimeout(scrollToBottom, 10);
          } else {
            setNewChatMessage(true);
          }
        });
      }
    }
    return () => {
      if (socket) {
        socket.off("new_message");
      }
    };
  }, [socket, scrollToBottom, isChatExpanded]);

  useEffect(() => {
    if (isChatExpanded) {
      scrollToBottom();
      setNewChatMessage(false);
    }
  }, [isChatExpanded, scrollToBottom]);

  const [roomState, setRoomState] = useState<{
    usersInRoom: {
      username: string;
      profilePhotoDownloadURL: string | null;
      profilePlaceholder?: string;
    }[];
    wordAttributionBar: string[];
    usernameColorMap: { [username: string]: string };
  }>({
    usersInRoom: [],
    wordAttributionBar: [],
    usernameColorMap: {},
  });

  const [maxNumAvatars] = useState(4); // Limit avatars in compressed view

  const [joinedRoom, setJoinedRoom] = useState(false);

  const [wordsAddedUsers, setWordsAddedUsers] = useState<{
    [userID: string]: Date;
  }>({});
  const [wordsDeletedUsers, setWordsDeletedUsers] = useState<{
    [userID: string]: Date;
  }>({});
  const [currentDate, setCurrentDate] = useState(new Date());

  useInterval(() => {
    setCurrentDate(new Date());
  }, 200);

  const computeBorderColor = useCallback(
    (username: string) => {
      if (
        wordsAddedUsers[username] &&
        currentDate.getTime() - wordsAddedUsers[username].getTime() <
          COLOR_DURATION
      ) {
        return "#53bf79"; // green
      } else if (
        wordsDeletedUsers[username] &&
        currentDate.getTime() - wordsDeletedUsers[username].getTime() <
          COLOR_DURATION
      ) {
        return "#ff6b6b"; // red
      } else {
        return null;
      }
    },
    [currentDate, wordsAddedUsers, wordsDeletedUsers]
  );

  useEffect(() => {
    if (socket) {
      socket.on("disconnect", () => {
        setJoinedRoom(false);
      });

      if (!joinedRoom && settled) {
        if (userID) {
          socket.emit("user_room_join", { roomID, userID, username });
          setJoinedRoom(true);
        } else {
          const unauthedUserHash = getUnauthedUserHash();
          socket.emit("user_room_join", {
            roomID,
            userID: `guest-${unauthedUserHash}`,
            username: randomUsername,
            isGuest: true,
          });
          setJoinedRoom(true);
        }
      }
    }
  }, [joinedRoom, randomUsername, roomID, socket, userID, settled, username]);

  useEffect(() => {
    if (socket) {
      socket.on("room_timer_update", (...args) => {
        if (args[0].timeLeftSeconds !== null) {
          setTimeLeftSeconds(args[0].timeLeftSeconds);
        }
        setEndTime(args[0].endTime ? new Date(args[0].endTime) : null);
      });
    }
    return () => {
      if (socket) {
        socket.off("room_timer_update");
      }
    };
  }, [socket]);

  useInterval(
    async () => {
      if (!endTime) return;
      if (new Date().getTime() > endTime.getTime()) {
        setEndTime(null);
        setTimeLeftSeconds(0);
        await updateRoomTimer({
          variables: {
            endTime: null,
            timeLeftSeconds: 0,
            roomID,
          },
        });
      } else {
        const timeMillisLeft = endTime.getTime() - new Date().getTime();
        const timeSecondsLeft = Math.ceil(timeMillisLeft / 1000);
        setTimeLeftSeconds(timeSecondsLeft);
      }
    },
    endTime ? 1000 : null
  );

  useEffect(() => {
    if (roomData?.getRoomData) {
      if (roomData.getRoomData.endTime) {
        const newEndTime = new Date(roomData.getRoomData.endTime);
        setEndTime(newEndTime);
        const timeMillisLeft = newEndTime.getTime() - new Date().getTime();
        const timeSecondsLeft = Math.ceil(timeMillisLeft / 1000);
        setTimeLeftSeconds(timeSecondsLeft);
      } else {
        setTimeLeftSeconds(roomData.getRoomData.timeLeftSeconds || 0);
        setEndTime(null);
      }
    }
  }, [roomData?.getRoomData]);

  const handlePlayClick = async () => {
    const newEndTime = new Date(Date.now() + timeLeftSeconds * 1000);
    setEndTime(newEndTime);
    await updateRoomTimer({
      variables: {
        endTime: newEndTime,
        roomID,
      },
    });
  };

  const handlePauseClick = async () => {
    setEndTime(null);
    await updateRoomTimer({
      variables: {
        endTime: null,
        timeLeftSeconds: timeLeftSeconds,
        roomID,
      },
    });
  };

  const handleTimeChange = async (newTimeLeftSeconds: number) => {
    setTimeLeftSeconds(newTimeLeftSeconds);
    await updateRoomTimer({
      variables: {
        timeLeftSeconds: newTimeLeftSeconds,
        roomID,
      },
    });
  };

  // Add effect to calculate max chat height
  useEffect(() => {
    const updateMaxHeight = () => {
      if (containerRef.current && isExpanded && isChatExpanded) {
        // Calculate how much space we have until viewport top
        const distanceToTop = containerRef.current.getBoundingClientRect().top;
        const maxHeightToFitViewport = distanceToTop - 20; // 20px buffer from top

        // Set height to minimum of 300px or available space
        const newMaxHeight = Math.min(300, maxHeightToFitViewport);

        setMaxChatHeight(Math.max(100, newMaxHeight));
      }
    };

    updateMaxHeight();
    const timeoutId = setTimeout(updateMaxHeight, 50);
    window.addEventListener("resize", updateMaxHeight);

    return () => {
      window.removeEventListener("resize", updateMaxHeight);
      clearTimeout(timeoutId);
    };
  }, [isExpanded, isChatExpanded]);

  useEffect(() => {
    if (socket) {
      socket.on("room_update", (...args) => {
        if (args[0].type === "room_state") {
          const newUsersInRoom = [];
          const usernamesAlreadyInRoom = new Set();
          for (const user of args[0].usersInRoom) {
            if (!usernamesAlreadyInRoom.has(user.username)) {
              newUsersInRoom.push(user);
              usernamesAlreadyInRoom.add(user.username);
            }
          }
          setRoomState({
            usersInRoom: newUsersInRoom,
            wordAttributionBar: args[0].wordAttributionBar,
            usernameColorMap: args[0].usernameColorMap,
          });
        } else if (args[0].type === "awareness_activity") {
          if (args[0].content === "word_added") {
            const numWordsAdded = (args[0].payload as { wordsAdded: number })
              .wordsAdded;
            const isGuest = (args[0].payload as { isGuest: boolean }).isGuest;
            setWordsAddedUsers({
              ...wordsAddedUsers,
              [args[0].username]: new Date(),
            });
            if (!isGuest) {
              setRoomState({
                ...roomState,
                wordAttributionBar: [
                  ...roomState.wordAttributionBar,
                  ...Array(numWordsAdded).fill(args[0].username),
                ],
                usernameColorMap: args[0].usernameColorMap,
              });
            }
          } else if (args[0].content === "word_deleted") {
            setWordsDeletedUsers({
              ...wordsDeletedUsers,
              [args[0].username]: new Date(),
            });
          }
        } else if (args[0].type === "words_written") {
          setWordsWritten(args[0].wordsWritten);
        }
      });
    }
    return () => {
      if (socket) {
        socket.off("room_update");
      }
    };
  }, [socket, roomState, wordsAddedUsers, wordsDeletedUsers]);

  // State 1: Not a group room
  if (!roomData?.getRoomData.isGroup) {
    return (
      <Button
        color="primary"
        className="font-sans"
        startContent={<ComponentIcon size={16} />}
        onClick={async () => {
          const { data } = await convertRoomToGroupSession({
            variables: { roomID },
            refetchQueries: [
              {
                query: GET_ROOM_DATA,
                variables: { roomID },
              },
            ],
          });
        }}
        style={{
          position: "fixed",
          bottom: 40,
          right: 40,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
        }}
      >
        Create room
      </Button>
    );
  }

  // State 2 & 3: Group room
  return (
    <div
      ref={containerRef}
      style={{
        position: "fixed",
        bottom: 40,
        right: 40,
        width: isExpanded ? 300 : 200,
        backgroundColor: "white",
        borderRadius: 12,
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        cursor: isExpanded ? "default" : "pointer",
        transition: "width 0.2s ease-out",
      }}
      onClick={() => !isExpanded && setIsExpanded(true)}
    >
      <div
        style={{
          borderRadius: 12,
          overflow: "hidden",
        }}
      >
        {/* Header with minimize button when expanded */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 10,
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          {roomData.getRoomData.circle?.imageDownloadURL && (
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: 10,
                marginRight: 8,
                backgroundImage: `url(${roomData.getRoomData.circle.imageDownloadURL})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
          <div style={{ flex: 1, minWidth: 0 }}>
            <p
              className="font-sans"
              style={{
                fontWeight: "bold",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {roomData.getRoomData.circle?.name || "Group writing room"}
            </p>
          </div>
          {isExpanded ? (
            <Button
              isIconOnly
              variant="light"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <Minimize2 size={16} color="#718096" />
            </Button>
          ) : (
            <Maximize2 size={16} color="#718096" />
          )}
        </div>

        {/* Expanded content */}
        {isExpanded ? (
          <>
            {/* Invite button */}
            <div style={{ padding: 10, borderBottom: "1px solid #E0E0E0" }}>
              <InviteButton />
            </div>

            {/* Words written */}
            <div style={{ padding: 10, borderBottom: "1px solid #E0E0E0" }}>
              <div className="flex justify-between items-center">
                <p className="font-sans">Words written in room</p>
                <p className="font-sans">{wordsWritten.toLocaleString()}</p>
              </div>
            </div>

            {/* Timer */}
            <div style={{ padding: 10, borderBottom: "1px solid #E0E0E0" }}>
              <ControlledRoomTimer
                timeLeftSeconds={timeLeftSeconds}
                endTime={endTime}
                isPaused={!endTime}
                onPauseClick={handlePauseClick}
                onPlayClick={handlePlayClick}
                onTimeChange={handleTimeChange}
              />
            </div>

            {/* Prompt */}
            {!roomData.getRoomData.promptHidden && (
              <div style={{ padding: 10, borderBottom: "1px solid #E0E0E0" }}>
                <PromptEditorNew
                  socket={socket}
                  setPromptHidden={(hidden) => {
                    // TODO: Implement prompt hiding
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {/* Timer */}
            <div style={{ padding: 10, borderBottom: "1px solid #E0E0E0" }}>
              <ControlledRoomTimer
                timeLeftSeconds={timeLeftSeconds}
                endTime={endTime}
                isPaused={!endTime}
                onPauseClick={handlePauseClick}
                onPlayClick={handlePlayClick}
                onTimeChange={handleTimeChange}
              />
            </div>
          </>
        )}

        {/* Word attribution bar - kept mounted for animation */}
        <div style={{ width: "100%" }}>
          <WordAttributionBar
            usernameWordList={roomState.wordAttributionBar}
            usernameColorMap={roomState.usernameColorMap}
            allowHover={isExpanded}
            maxWords={isExpanded ? undefined : 100}
          />
        </div>

        {isExpanded && (
          /* Chat section */
          <div
            style={{
              borderTop: "1px solid #E0E0E0",
              transition: "height 0.2s ease-out",
              height: isChatExpanded ? `${maxChatHeight}px` : "40px",
              overflow: "hidden",
            }}
          >
            {/* Chat header/toggle */}
            <div
              style={{
                padding: "8px 12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                backgroundColor: isChatExpanded ? "#f7fafc" : "white",
              }}
              onClick={() => {
                setIsChatExpanded(!isChatExpanded);
                if (!isChatExpanded) {
                  setNewChatMessage(false);
                }
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <p className="font-sans">Chat</p>
                {newChatMessage && !isChatExpanded && (
                  <div
                    style={{
                      width: 6,
                      height: 6,
                      borderRadius: "50%",
                      backgroundColor: "#456577",
                    }}
                  />
                )}
              </div>
              <ArrowUp2
                size={16}
                color="#718096"
                style={{
                  transform: isChatExpanded ? "rotate(180deg)" : "none",
                  transition: "transform 0.2s ease-out",
                }}
              />
            </div>

            {/* Chat messages */}
            <div
              ref={scrollWindowRef}
              style={{
                height: `calc(${maxChatHeight}px - 88px)`,
                overflowY: "auto",
                padding: "0 12px",
              }}
            >
              {messages.map((message) => (
                <div key={message.messageID} style={{ marginBottom: 10 }}>
                  <div className="font-sans flex flex-row justify-between items-center">
                    <a
                      href={`/writer/${message.username}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontWeight: "bold", fontSize: 12 }}
                    >
                      {message.username}
                    </a>
                    <p className="font-mono" style={{ fontSize: 12 }}>
                      {new Date(message.createdAt).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p>
                  </div>
                  <p className="font-sans" style={{ fontSize: 12 }}>
                    {message.content}
                  </p>
                </div>
              ))}
            </div>

            {/* Chat input */}
            <div
              style={{
                padding: "8px 12px",
                borderTop: "1px solid #E0E0E0",
                display: "flex",
                gap: 8,
              }}
            >
              <Input
                placeholder="Chat here"
                value={messageInput}
                onValueChange={setMessageInput}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && messageInput.trim()) {
                    sendMessage();
                  }
                }}
                classNames={{
                  inputWrapper: ["h-[24px] font-sans"],
                }}
                size="sm"
                variant="underlined"
              />
              <Button
                size="sm"
                isDisabled={!messageInput.trim()}
                onClick={sendMessage}
              >
                Send
              </Button>
            </div>
          </div>
        )}

        {/* User avatars */}
        <div
          style={{
            padding: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 4,
            borderTop: "1px solid #E0E0E0",
          }}
        >
          {roomState.usersInRoom.slice(0, maxNumAvatars).map((user) => (
            <div key={user.username}>
              <Tooltip text={user.username}>
                <ProfileAvatar
                  src={user.profilePhotoDownloadURL ?? ""}
                  profilePlaceholder={user.profilePlaceholder}
                  style={{
                    width: 24,
                    height: 24,
                    outlineWidth: "2px",
                    outlineStyle: "solid",
                    outlineColor:
                      computeBorderColor(user.username) || "transparent",
                    outlineOffset: "1px",
                    transitionProperty: "outline-color",
                    transitionDuration: "0.2s",
                    transitionTimingFunction: "ease-out",
                  }}
                />
              </Tooltip>
            </div>
          ))}
          {roomState.usersInRoom.length > maxNumAvatars && (
            <div>
              <Tooltip
                text={`${
                  roomState.usersInRoom.length - maxNumAvatars
                } more users`}
              >
                <Avatar
                  name={`+${roomState.usersInRoom.length - maxNumAvatars}`}
                  showFallback
                  style={{ width: 24, height: 24 }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
