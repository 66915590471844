import { gql, useQuery } from "@apollo/client";

import HomepageHeader from "../components/HomepageHeader";

import { clearExportKey, clearLocalStorage } from "../crypto/utils";
import RoomsGrid from "../components/RoomsGrid";
import { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import WritingGroupsList from "../components/WritingGroupsList";
import DraftList from "../components/DraftList";

const CHECK_LOGGED_IN = gql`
  query CheckLoggedIn {
    isLoggedIn
  }
`;

// const SEED_DATA = gql`
//   mutation SeedData {
//     seedData {
//       success
//     }
//   }
// `;

function Home() {
  const { data, loading } = useQuery(CHECK_LOGGED_IN, {
    fetchPolicy: "no-cache",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 650px)",
  });

  const isLoggedIn = useMemo(() => {
    return data && data.isLoggedIn;
  }, [data]);

  useEffect(() => {
    if (!isLoggedIn && !loading) {
      clearExportKey();
    }
  }, [isLoggedIn, loading]);

  if (loading) {
    return null;
  }

  return (
    <div>
      <div>
        <HomepageHeader />
      </div>
      <div className="flex flex-row w-full justify-center relative">
        <div className="flex flex-col w-full">
          <DraftList />
        </div>
        {/* <RoomsGrid /> */}
        {!isMobile && <WritingGroupsList />}
      </div>
    </div>
  );
}

export default Home;
