import { Button, Input } from "@nextui-org/react";
import { gql, useQuery } from "@apollo/client";
import { useEffect, useMemo, useRef, useState } from "react";
import { useUserInfo } from "../hooks/useUserInfo";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../redux/store";
import Logo from "../assets/logo.png";
import { Socket } from "socket.io-client";
import { useMediaQuery } from "react-responsive";

const GET_ROOM_DATA = gql`
  query getRoomDataDocumentEditor($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      roomID
      prompt
      timeLeftSeconds
      name
      ownerID
      presetID
    }
  }
`;

function UnauthenticatedDocumentEditorHeader({
  socket,
}: {
  socket: Socket | null;
}) {
  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });
  const { userID } = useUserInfo();
  const navigate = useNavigate();

  const { draftID, roomID } = useParams();

  const [lastDraftID, setLastDraftID] = useState<string | null>(null);
  const [roomName, setRoomName] = useState<string | null>(null);
  useEffect(() => {
    if (!lastDraftID && draftID) {
      setLastDraftID(draftID);
    }
  }, [draftID, lastDraftID]);

  // if user is navigating between drafts, blow everything away
  useEffect(() => {
    if (draftID && lastDraftID && draftID !== lastDraftID) {
      navigate(0); // equivalent to window.location.reload()
    }
  }, [draftID, lastDraftID, navigate]);

  const { data: roomData } = useQuery(GET_ROOM_DATA, {
    variables: { roomID },
    skip: !roomID,
    onCompleted: (data) => {
      setRoomName(data.getRoomData.name);
    },
  });

  const isPresetRoom = useMemo(() => {
    return !!roomData?.getRoomData?.presetID;
  }, [roomData?.getRoomData?.presetID]);


  const savedDraftTitle = useSelector((state: RootState) => state.draft.title);

  const [draftTitle, setDraftTitle] = useState("");

  useEffect(() => {
    if (savedDraftTitle && savedDraftTitle !== draftTitle) {
      setDraftTitle(savedDraftTitle);
    }
  }, [draftTitle, savedDraftTitle]);

  const [inputWidth, setInputWidth] = useState(0);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const updateInputWidth = () => {
      if (hiddenSpanRef.current) {
        const width = hiddenSpanRef.current.getBoundingClientRect().width;
        const screenWidth = window.innerWidth;
        setInputWidth(Math.min(width, screenWidth - (isMobile ? 320 : 600)));
      }
    };

    // Initial update
    updateInputWidth();

    // Add resize event listener
    window.addEventListener("resize", updateInputWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateInputWidth);
    };
  }, [roomName, isMobile]);

  const isRoomOwner = useMemo(() => {
    return userID === roomData?.getRoomData?.ownerID;
  }, [userID, roomData?.getRoomData?.ownerID]);

  return (
    <>
      <div
        className="w-full flex flex-row py-2 items-center border-b border-solid border-black px-3 fixed"
        style={{ backgroundColor: "white", zIndex: 1000 }}
      >
        <a href="/">
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: 10,
            }}
          />
        </a>
        <div className="flex-1" style={{ paddingLeft: 12 }}>
          <div className="flex flex-row items-center">
            <div className="flex-1">
              <Input
                placeholder="Document Title"
                classNames={{
                  input: ["font-sans font-semibold text-lg"],
                  inputWrapper: [
                    "bg-transparent",
                    "shadow-none",
                    "data-[hover=true]:bg-transparent",
                    "group-data-[focus=true]:bg-transparent",
                    "h-[48px]",
                    "py-[5px]",
                    "w-full",
                    "px-0",
                    "min-h-unit-0",
                  ],
                  innerWrapper: ["pb-0"],
                }}
                value={draftTitle}
                isReadOnly={true}
              />
            </div>
          </div>
        </div>
        <a href="/signup">
          <Button variant="bordered" color="primary" className="font-sans">
            Create account to save your draft
          </Button>
        </a>
      </div>
      <div style={{ minHeight: 57 }}></div>
    </>
  );
}

export default UnauthenticatedDocumentEditorHeader;
