import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import CreateAccount from "./pages/CreateAccount";
import reportWebVitals from "./reportWebVitals";
import { NextUIProvider } from "@nextui-org/react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import Login from "./pages/Login";
import EditorPage from "./pages/Editor";
import Home from "./pages/Home";
import ProtectedRoute from "./pages/ProtectedRoute";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";
import UpdatePassword from "./pages/UpdatePassword";
import RecoverAccount from "./pages/RecoverAccount";
import Profile from "./pages/Profile";
import ViewAllDrafts from "./pages/ViewAllDrafts";
import Settings from "./pages/Settings";
import Welcome from "./pages/Welcome";
import RoomPage from "./pages/RoomPage";
import CreateRoomPage from "./pages/CreateRoomPage";
import CreateWritingCirclePage from "./pages/CreateWritingCirclePage";
import WritingCircleDashboard from "./pages/WritingCircleDashboard";
import WritingCircleSettings from "./pages/WritingCircleSettings";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  cache: new InMemoryCache(),
  credentials: "include",
});
const router = createBrowserRouter([
  {
    path: "/signup",
    element: <CreateAccount />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/writer/:username",
    element: <Profile />,
  },
  {
    path: "/room/:roomID",
    element: <RoomPage />,
  },
  {
    path: "/room/:roomID/draft/:draftID",
    element: <EditorPage />,
  },
  {
    path: "/create-room",
    element: <CreateRoomPage />,
  },
  {
    path: "/circle/:circleID",
    element: <WritingCircleDashboard />,
  },
  {
    path: "/circle/:circleID/post/:postID",
    element: <WritingCircleDashboard />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/draft/:draftID",
        element: <EditorPage />,
      },
      {
        path: "/update-password",
        element: <UpdatePassword />,
      },
      {
        path: "/select-draft",
        element: <ViewAllDrafts />,
      },
      { path: "/settings", element: <Settings /> },
      { path: "/welcome", element: <Welcome /> },
      {
        path: "/create-writing-circle",
        element: <CreateWritingCirclePage />,
      },
      {
        path: "/circle/:circleID/settings",
        element: <WritingCircleSettings />,
      },
    ],
  },
  { path: "/", element: <Home /> },
  { path: "/recover-account", element: <RecoverAccount /> },
]);
root.render(
  <React.StrictMode>
    <NextUIProvider>
      <ReduxProvider store={store}>
        <ApolloProvider client={client}>
          <title>Draft Zero</title>
          {/* <WebsocketProvider> */}
          <RouterProvider router={router} />
          {/* </WebsocketProvider> */}
        </ApolloProvider>
      </ReduxProvider>
    </NextUIProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
