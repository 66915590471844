import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import { useMemo, useState } from "react";
import interpolate from "color-interpolate";
import { convertSecondsToString } from "../utils/string";
import { useMediaQuery } from "react-responsive";

dayjs.extend(dayOfYear);

const GET_WORD_LOG_GRID = gql`
  query GetWordLogGrid($username: String!, $localTz: String!) {
    getWordLogGrid(request: { username: $username, localTz: $localTz }) {
      days {
        date
        wordsAdded
        wordsDeleted
      }
    }
  }
`;

function ActivityGrid({ vertical = false }: { vertical?: boolean }) {
  const { username } = useParams();
  const isMobile = useMediaQuery({
    query: "(max-width: 1060px)",
  });
  // get local timezone
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const { data: wordLogData } = useQuery(GET_WORD_LOG_GRID, {
    variables: { username, localTz },
  });
  // construct grid where each column is one week
  // mouseover to see details

  const maxSeconds = useMemo(() => {
    return wordLogData?.getWordLogGrid.days.reduce(
      (acc: number, day: any) =>
        Math.max(acc, day.wordsAdded + day.wordsDeleted),
      0
    );
  }, [wordLogData?.getWordLogGrid.days]);

  const colorMap = interpolate(["#fefefe", "#7194A8"]);

  if (vertical) {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateRows: `repeat(54, 32px)`,
          gridTemplateColumns: `repeat(7, 32px)`,
          marginBottom: 50,
        }}
      >
        {wordLogData?.getWordLogGrid.days.map((day: any, index: number) => {
          return (
            <div
              style={{
                gridColumn: (index % 7) + 1,
                gridRow: 54 - Math.floor(index / 7),
                backgroundColor:
                  maxSeconds > 0
                    ? colorMap((day.wordsAdded + day.wordsDeleted) / maxSeconds)
                    : colorMap(0),
                margin: "2px",
                position: "relative",
                borderRadius: 4,
                borderWidth: 3,
                borderColor:
                  day.wordsAdded + day.wordsDeleted > 0 ? "#456577" : "#eeeeee",
              }}
              onMouseEnter={() => setHoveredDate(day.date)}
              onMouseLeave={() => setHoveredDate(null)}
              key={day.date}
            >
              {hoveredDate === day.date && (
                <div
                  style={{
                    position: "absolute",
                    top: -35,
                    left: "calc(-40px + calc(clamp(0px, calc((100vw - 300px) / 53), 15px) / 2))",
                    width: 80,
                    zIndex: 1,
                    cursor: "default",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "white",
                    boxShadow: "0 0 10px #FFFFFF",
                  }}
                >
                  <p
                    className="font-sans"
                    style={{ fontSize: 10, color: "black", opacity: 1 }}
                  >
                    <span style={{ color: "green" }}>+{day.wordsAdded}</span>/
                    <span style={{ color: "red" }}>-{day.wordsDeleted}</span> on
                  </p>
                  <p
                    className="font-sans"
                    style={{ fontSize: 10, color: "black", opacity: 1 }}
                  >
                    {dayjs(day.date).format("MMM D, YYYY")}
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div style={{ height: isMobile ? "100%" : 159 }}>
      {wordLogData?.getWordLogGrid.days && (
        <div
          style={{
            display: "grid",
            gridTemplateRows: `repeat(7, clamp(0px, calc((100dvw${
              isMobile ? " - 60px" : " - 320px"
            }) / 53), 15px))`,
            gridTemplateColumns: `repeat(54, clamp(0px, calc((100dvw${
              isMobile ? " - 60px" : " - 320px"
            }) / 53), 15px))`,
          }}
        >
          {wordLogData?.getWordLogGrid.days.map((day: any, index: number) => {
            return (
              <div
                style={{
                  gridColumn: Math.floor(index / 7) + 1,
                  gridRow: (index % 7) + 1,
                  backgroundColor:
                    maxSeconds > 0
                      ? colorMap(
                          (day.wordsAdded + day.wordsDeleted) / maxSeconds
                        )
                      : colorMap(0),
                  margin: "1px",
                  position: "relative",
                  borderRadius: 2,
                  borderWidth: 1,
                  borderColor:
                    day.wordsAdded + day.wordsDeleted > 0
                      ? "#8884d8"
                      : "#eeeeee",
                }}
                onMouseEnter={() => setHoveredDate(day.date)}
                onMouseLeave={() => setHoveredDate(null)}
                key={day.date}
              >
                {hoveredDate === day.date && (
                  <div
                    style={{
                      position: "absolute",
                      top: -35,
                      left: "calc(-40px + calc(clamp(0px, calc((100vw - 300px) / 53), 15px) / 2))",
                      width: 80,
                      zIndex: 1,
                      cursor: "default",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "white",
                      boxShadow: "0 0 10px #FFFFFF",
                    }}
                  >
                    <p
                      className="font-sans"
                      style={{ fontSize: 10, color: "black", opacity: 1 }}
                    >
                      <span style={{ color: "green" }}>+{day.wordsAdded}</span>/
                      <span style={{ color: "red" }}>-{day.wordsDeleted}</span>{" "}
                      on
                    </p>
                    <p
                      className="font-sans"
                      style={{ fontSize: 10, color: "black", opacity: 1 }}
                    >
                      {dayjs(day.date).format("MMM D, YYYY")}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ActivityGrid;
