import nacl from "tweetnacl";
import {
  fromByteArray as b64FromByteArray,
  toByteArray as b64ToByteArray,
} from "base64-js";
import base64 from "base-64";
import { syncScrypt } from "scrypt-js";
import * as bip39 from "@scure/bip39";
import { wordlist } from "@scure/bip39/wordlists/english";

function newNonce() {
  return nacl.randomBytes(nacl.box.nonceLength);
}

export const utf8StringToBytes = (data: string) => {
  const utf8encoder = new TextEncoder();
  return utf8encoder.encode(data);
};

export const utf8BytesToString = (data: Uint8Array) => {
  const utf8decoder = new TextDecoder();
  return utf8decoder.decode(data);
};

export function encryptAsymmetric({
  secretKey,
  decryptedPayload,
}: {
  secretKey: string;
  decryptedPayload: Uint8Array;
}) {
  const secretKeyBytes = toByteArray(base64.encode(secretKey));
  const salt = nacl.randomBytes(32);
  const derivedSecretKey = syncScrypt(secretKeyBytes, salt, 1024, 8, 1, 32);
  const nonce = newNonce();
  const encrypted = nacl.box.after(decryptedPayload, nonce, derivedSecretKey);

  const fullMessage = new Uint8Array(nonce.length + encrypted.length);
  fullMessage.set(nonce);
  fullMessage.set(encrypted, nonce.length);

  const encodedMessage = fromByteArray(fullMessage);
  return encodedMessage;
}

export function decryptAsymmetric({
  publicKey,
  encryptedPayload,
}: {
  publicKey: string;
  encryptedPayload: string;
}) {
  const publicKeyBytes = toByteArray(publicKey);
  const messageWithNonceAsUint8Array = toByteArray(encryptedPayload);
  const nonce = messageWithNonceAsUint8Array.slice(0, nacl.box.nonceLength);
  const message = messageWithNonceAsUint8Array.slice(
    nacl.box.nonceLength,
    messageWithNonceAsUint8Array.length
  );

  const decrypted = nacl.box.open.after(message, nonce, publicKeyBytes);
  if (!decrypted) {
    throw new Error("Could not decrypt message");
  }
  return utf8BytesToString(decrypted);
}

function derive32ByteSecretKey({
  exportKey,
  salt,
}: {
  exportKey: string;
  salt: Uint8Array;
}) {
  const secretKeyBytes = toByteArray(base64.encode(exportKey));
  return syncScrypt(secretKeyBytes, salt, 1024, 8, 1, 32);
}

export function encryptSymmetric({
  secretKey,
  decryptedPayload,
}: {
  secretKey: string;
  decryptedPayload: Uint8Array;
}) {
  const nonce = newNonce();
  const salt = nacl.randomBytes(32);
  const derivedSecretKey = derive32ByteSecretKey({
    exportKey: secretKey,
    salt,
  });
  const encrypted = nacl.secretbox(decryptedPayload, nonce, derivedSecretKey);
  const fullMessage = new Uint8Array(
    nonce.length + salt.length + encrypted.length
  );
  fullMessage.set(nonce);
  fullMessage.set(salt, nonce.length);
  fullMessage.set(encrypted, nonce.length + salt.length);
  const encodedMessage = fromByteArrayB64(fullMessage);
  return encodedMessage;
}

export function encryptSymmetricString({
  secretKey,
  decryptedPayload,
}: {
  secretKey: string;
  decryptedPayload: string;
}) {
  return encryptSymmetric({
    secretKey,
    decryptedPayload: utf8StringToBytes(decryptedPayload),
  });
}

export function decryptSymmetricString({
  secretKey,
  encryptedPayload,
}: {
  secretKey: string;
  encryptedPayload: string | null | undefined;
}) {
  if (!encryptedPayload) {
    return null;
  }
  return utf8BytesToString(
    decryptSymmetric({
      secretKey,
      encryptedPayload,
    })
  );
}

export function decryptSymmetric({
  secretKey,
  encryptedPayload,
}: {
  secretKey: string;
  encryptedPayload: string;
}) {
  const messageWithNonceAsUint8Array = toByteArrayB64(encryptedPayload);
  const nonce = messageWithNonceAsUint8Array.slice(
    0,
    nacl.secretbox.nonceLength
  );
  const salt = messageWithNonceAsUint8Array.slice(
    nacl.secretbox.nonceLength,
    nacl.secretbox.nonceLength + 32
  );
  const message = messageWithNonceAsUint8Array.slice(
    nacl.secretbox.nonceLength + 32,
    messageWithNonceAsUint8Array.length
  );
  const derivedSecretKey = derive32ByteSecretKey({
    exportKey: secretKey,
    salt,
  });
  const decrypted = nacl.secretbox.open(message, nonce, derivedSecretKey);
  if (!decrypted) {
    throw new Error("Could not decrypt message");
  }
  return decrypted;
}

export function setExportKey(exportKey: string) {
  localStorage.setItem("exportKey", exportKey);
}

export function getExportKey() {
  return localStorage.getItem("exportKey");
}

export function clearExportKey() {
  localStorage.removeItem("exportKey");
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function generateMnemonic() {
  return bip39.generateMnemonic(wordlist, 256);
}

export function deriveKeyFromMnemonic({ mnemonic }: { mnemonic: string }) {
  return bip39.mnemonicToSeedSync(mnemonic);
}

export function hashString(payload: string) {
  return fromByteArrayB64(nacl.hash(toByteArray(payload)));
}

export function base64EncodeString(payload: string) {
  return base64.encode(payload);
}

export function base64DecodeString(payload: string) {
  return base64.decode(payload);
}

export function toByteArray(payload: string) {
  return b64ToByteArray(base64.encode(payload));
}

export function fromByteArray(payload: Uint8Array) {
  return base64.decode(b64FromByteArray(payload));
}

export function toByteArrayB64(b64: string) {
  return b64ToByteArray(b64);
}

export function fromByteArrayB64(bytes: Uint8Array) {
  return b64FromByteArray(bytes);
}
