import { useState } from "react";
import { pluralize } from "../utils/string";
import { Tooltip } from "./primitives/Tooltip";

export default function WordAttributionBar({
  usernameWordList,
  usernameColorMap,
  allowHover = true,
  maxWords,
}: {
  usernameWordList: string[] | undefined;
  usernameColorMap: { [username: string]: string };
  allowHover?: boolean;
  maxWords?: number;
}) {
  const [isHovering, setIsHovering] = useState(false);

  // Show placeholder if no data
  if (!usernameWordList || usernameWordList.length === 0) {
    return (
      <div className="flex flex-col w-full" style={{ padding: 10 }}>
        <div className="flex h-4 w-full rounded overflow-hidden">
          <div style={{ backgroundColor: '#E2E8F0', width: '100%' }} />
        </div>
      </div>
    );
  }

  // Calculate word counts for the legend
  const wordCounts = Object.entries(
    usernameWordList.reduce((acc, username) => {
      acc[username] = (acc[username] || 0) + 1;
      return acc;
    }, {} as Record<string, number>)
  )
    .sort(([, a], [, b]) => b - a)
    .reduce((acc, [username, count]) => {
      acc[username] = count;
      return acc;
    }, {} as Record<string, number>);

  // Always show the last N words
  const displayWordList = maxWords 
    ? usernameWordList.slice(-maxWords)
    : usernameWordList;

  const combinedSlices = displayWordList.reduce(
    (acc: Array<{ username: string; count: number }>, username, index) => {
      if (index === 0 || username !== displayWordList[index - 1]) {
        acc.push({
          username,
          count: 1,
        });
      } else {
        acc[acc.length - 1].count++;
      }
      return acc;
    },
    []
  );

  return (
    <div
      className="flex flex-col w-full relative"
      onMouseEnter={() => {
        if (allowHover) {
          setIsHovering(true);
        }
      }}
      onMouseLeave={() => {
        if (allowHover) {
          setIsHovering(false);
        }
      }}
      style={{ padding: 10 }}
    >
      {/* Legend that appears on hover */}
      {isHovering && (
        <div
          style={{
            position: "absolute",
            top: -200,
            height: 200,
            left: 0,
            backgroundColor: "white",
            zIndex: 100,
            width: "100%",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            padding: "12px",
            borderRadius: "4px",
            overflowY: "auto",
          }}
        >
          <p
            className="font-sans"
            style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}
          >
            Last {maxWords ? `${maxWords} of ${usernameWordList.length}` : usernameWordList.length} words in room:
          </p>
          {Object.entries(wordCounts).map(([username, count], index) => (
            <div
              key={index}
              className="flex items-start gap-2 text-sm"
              style={{ marginBottom: 10 }}
            >
              <div
                className="w-4 h-4 rounded"
                style={{
                  backgroundColor: `#${usernameColorMap[username]}`,
                  opacity: 0.8,
                }}
              />
              <div style={{ marginTop: -2 }}>
                <p className="font-sans" style={{ fontSize: 16 }}>
                  {username}
                </p>
                <p className="font-sans" style={{ fontSize: 10 }}>
                  {count} {pluralize({ count, word: "word" })}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex h-4 w-full rounded overflow-hidden relative">
        {/* Combine consecutive word slices */}
        {combinedSlices.map(({ username, count }, index) => (
          <div
            key={`${index}-${username}`}
            style={{
              backgroundColor: `#${usernameColorMap[username]}`,
              opacity: 0.8,
              flexBasis: `${(count * 100) / displayWordList.length}%`,
              transition: "flex-basis 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
            }}
            title={`${username} (${count} ${pluralize({
              count,
              word: "word",
            })})`}
          />
        ))}
      </div>
    </div>
  );
}
