import { Button } from "@nextui-org/react";
import { Pause, Play } from "iconsax-react";
import { useState, useEffect } from "react";
import { useInterval } from "../hooks/useInterval";

interface ControlledRoomTimerProps {
  timeLeftSeconds: number;
  endTime: Date | null;
  isPaused: boolean;
  onPauseClick: () => void;
  onPlayClick: () => void;
  onTimeChange: (newTimeLeftSeconds: number) => void;
}

export default function ControlledRoomTimer({
  timeLeftSeconds,
  endTime,
  isPaused,
  onPauseClick,
  onPlayClick,
  onTimeChange,
}: ControlledRoomTimerProps) {
  const [editingField, setEditingField] = useState<"hours" | "minutes" | "seconds" | null>(null);
  const [displayTimeLeftHours, setDisplayTimeLeftHours] = useState<number>(0);
  const [displayTimeLeftMinutes, setDisplayTimeLeftMinutes] = useState<number>(0);
  const [displayTimeLeftSeconds, setDisplayTimeLeftSeconds] = useState<number>(0);
  const [timerExpired, setTimerExpired] = useState<boolean>(false);
  const [timerExpiredColor, setTimerExpiredColor] = useState<string>("rgb(242, 17, 97)");

  // Update display time when timeLeftSeconds changes
  useEffect(() => {
    setDisplayTimeLeftHours(Math.floor(timeLeftSeconds / 3600));
    setDisplayTimeLeftMinutes(Math.floor((timeLeftSeconds % 3600) / 60));
    setDisplayTimeLeftSeconds(timeLeftSeconds % 60);
  }, [timeLeftSeconds]);

  useInterval(() => {
    if (timerExpiredColor === "rgb(242, 17, 97)") {
      setTimerExpiredColor("black");
    } else {
      setTimerExpiredColor("rgb(242, 17, 97)");
    }
  }, 700);

  return (
    <div className="flex flex-row items-center">
      <Button
        variant="light"
        isIconOnly
        isDisabled={timeLeftSeconds === 0}
        onClick={() => {
          if (isPaused) {
            onPlayClick();
          } else {
            onPauseClick();
          }
        }}
        size="sm"
        style={{ color: isPaused ? "#7194A8" : "#456577" }}
      >
        {isPaused ? <Play size={16} /> : <Pause size={16} />}
      </Button>
      <div
        className="flex items-center"
        style={{
          color: timerExpired ? timerExpiredColor : isPaused ? "#7194A8" : "#456577",
        }}
      >
        <input
          readOnly={!isPaused}
          onFocus={() => {
            setEditingField("hours");
            setTimerExpired(false);
          }}
          maxLength={2}
          onBlur={() => {
            setEditingField(null);
            if (displayTimeLeftHours === -1) {
              setDisplayTimeLeftHours(0);
            }
            const correctedHours = displayTimeLeftHours >= 0 ? displayTimeLeftHours : 0;
            const newTimeLeftSeconds =
              correctedHours * 3600 + displayTimeLeftMinutes * 60 + displayTimeLeftSeconds;
            onTimeChange(newTimeLeftSeconds);
          }}
          onChange={(e) => {
            setDisplayTimeLeftHours(
              e.target.value === "" ? -1 : parseInt(e.target.value.slice(0, 2))
            );
          }}
          type={editingField !== "hours" ? "text" : "number"}
          value={
            editingField !== "hours"
              ? displayTimeLeftHours?.toString().padStart(2, "0") || "00"
              : displayTimeLeftHours >= 0
              ? displayTimeLeftHours
              : ""
          }
          className="w-6 bg-transparent font-mono leading-none"
          style={{
            outline: isPaused ? undefined : "none",
            outlineColor: timerExpired ? timerExpiredColor : "#7194A8",
            fontSize: 20,
            paddingTop: 4,
            cursor: isPaused ? undefined : "default",
          }}
        />
        <span
          className="inline-flex items-center justify-center font-mono leading-none"
          style={{ cursor: isPaused ? undefined : "default" }}
        >
          :
        </span>
        <input
          readOnly={!isPaused}
          maxLength={2}
          onFocus={() => {
            setEditingField("minutes");
            setTimerExpired(false);
          }}
          onBlur={() => {
            setEditingField(null);
            if (displayTimeLeftMinutes === -1) {
              setDisplayTimeLeftMinutes(0);
            }
            const correctedMinutes = displayTimeLeftMinutes >= 0 ? displayTimeLeftMinutes : 0;
            const newTimeLeftSeconds =
              displayTimeLeftHours * 3600 + correctedMinutes * 60 + displayTimeLeftSeconds;
            onTimeChange(newTimeLeftSeconds);
          }}
          onChange={(e) => {
            setDisplayTimeLeftMinutes(
              e.target.value === "" ? -1 : parseInt(e.target.value.slice(0, 2))
            );
          }}
          type={editingField !== "minutes" ? "text" : "number"}
          value={
            editingField !== "minutes"
              ? displayTimeLeftMinutes?.toString().padStart(2, "0") || "00"
              : displayTimeLeftMinutes >= 0
              ? displayTimeLeftMinutes
              : ""
          }
          className="w-6 bg-transparent text-center font-mono leading-none"
          style={{
            outline: isPaused ? undefined : "none",
            outlineColor: timerExpired ? timerExpiredColor : "#7194A8",
            fontSize: 20,
            paddingTop: 4,
            cursor: isPaused ? undefined : "default",
          }}
        />
        <span
          className="inline-flex items-center justify-center font-mono leading-none"
          style={{ cursor: isPaused ? undefined : "default" }}
        >
          :
        </span>
        <input
          readOnly={!isPaused}
          maxLength={2}
          onFocus={() => {
            setEditingField("seconds");
            setTimerExpired(false);
          }}
          onBlur={() => {
            setEditingField(null);
            if (displayTimeLeftSeconds === -1) {
              setDisplayTimeLeftSeconds(0);
            }
            const correctedSeconds = displayTimeLeftSeconds >= 0 ? displayTimeLeftSeconds : 0;
            const newTimeLeftSeconds =
              displayTimeLeftHours * 3600 + displayTimeLeftMinutes * 60 + correctedSeconds;
            onTimeChange(newTimeLeftSeconds);
          }}
          onChange={(e) => {
            setDisplayTimeLeftSeconds(
              e.target.value === "" ? -1 : parseInt(e.target.value.slice(0, 2))
            );
          }}
          type={editingField !== "seconds" ? "text" : "number"}
          value={
            editingField !== "seconds"
              ? displayTimeLeftSeconds?.toString().padStart(2, "0") || "00"
              : displayTimeLeftSeconds >= 0
              ? displayTimeLeftSeconds
              : ""
          }
          className="w-6 bg-transparent text-center font-mono leading-none"
          style={{
            outline: isPaused ? undefined : "none",
            outlineColor: timerExpired ? timerExpiredColor : "#7194A8",
            fontSize: 20,
            paddingTop: 4,
            cursor: isPaused ? undefined : "default",
          }}
        />
      </div>
    </div>
  );
} 