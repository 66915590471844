import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  Card,
  CardBody,
  Button,
  Input,
  Textarea,
  Avatar,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Switch,
} from "@nextui-org/react";
import { useDropzone } from "react-dropzone";
import { ImageUploadLinkResponse } from "../gql/graphql";
import { CloseCircle } from "iconsax-react";
import PlaintextTitleHeader from "../components/PlaintextTitleHeader";
import { ArrowLeft } from "lucide-react";

const GET_WRITING_CIRCLE_DATA = gql`
  query GetWritingCircleDataSettings($request: GetWritingCircleDataRequest!) {
    getWritingCircleData(request: $request) {
      circleID
      name
      description
      imageDownloadURL
      isAdmin
      presetID
      notifyOnPost
    }
  }
`;

const UPDATE_WRITING_CIRCLE_SETTINGS = gql`
  mutation UpdateWritingCircleSettings(
    $circleID: String!
    $name: String!
    $description: String
    $profileImageParameters: ImageParameters
    $presetID: String
  ) {
    updateWritingCircleSettings(
      request: {
        circleID: $circleID
        name: $name
        description: $description
        profileImageParameters: $profileImageParameters
        presetID: $presetID
      }
    ) {
      resourceID
    }
  }
`;

const UPDATE_PERSONAL_WRITING_CIRCLE_SETTINGS = gql`
  mutation UpdatePersonalWritingCircleSettings(
    $circleID: String!
    $notifyOnPost: Boolean!
  ) {
    updatePersonalWritingCircleSettings(
      request: { circleID: $circleID, notifyOnPost: $notifyOnPost }
    ) {
      success
    }
  }
`;

const GET_PROFILE_PHOTO_UPLOAD_URL = gql`
  query GetImageUploadLink {
    getImageUploadLink {
      imageID
      link
      fields
    }
  }
`;

const SET_IMAGE_UPLOADED = gql`
  mutation SetImageUploaded($imageID: String!) {
    setImageUploaded(request: { imageID: $imageID }) {
      photoDownloadURL
    }
  }
`;

const GET_ROOM_IMAGE_PRESETS = gql`
  query GetRoomImagePresets {
    getRoomImagePresets {
      presetID
      imageURL
    }
  }
`;

export default function WritingCircleSettings() {
  const { circleID } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [selectedPresetID, setSelectedPresetID] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageSelectorOpen, setImageSelectorOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [notifyOnPost, setNotifyOnPost] = useState(false);
  const { data, loading } = useQuery(GET_WRITING_CIRCLE_DATA, {
    variables: {
      request: {
        circleID,
      },
    },
    onCompleted: (data) => {
      setIsAdmin(data.getWritingCircleData.isAdmin);
      setTitle(data.getWritingCircleData.name);
      setDescription(data.getWritingCircleData.description);
      setSelectedPresetID(data.getWritingCircleData.presetID || "");
      setNotifyOnPost(data.getWritingCircleData.notifyOnPost);
    },
    fetchPolicy: "no-cache",
  });

  const [updateSettings] = useMutation(UPDATE_WRITING_CIRCLE_SETTINGS, {
    onCompleted: () => {
      navigate(`/circle/${circleID}`);
    },
  });

  const [updatePersonalSettings] = useMutation(
    UPDATE_PERSONAL_WRITING_CIRCLE_SETTINGS
  );

  const [getProfilePhotoUploadLink] = useLazyQuery(
    GET_PROFILE_PHOTO_UPLOAD_URL,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [setImageUploaded] = useMutation(SET_IMAGE_UPLOADED);

  const { data: presetData } = useQuery(GET_ROOM_IMAGE_PRESETS);
  const objectURL = useMemo(
    () => (selectedFile ? URL.createObjectURL(selectedFile) : ""),
    [selectedFile]
  );

  const selectedImageURL = useMemo(() => {
    return selectedPresetID === "custom"
      ? objectURL
      : presetData?.getRoomImagePresets.find(
          (preset: { presetID: string; imageURL: string }) =>
            preset.presetID === selectedPresetID
        )?.imageURL ||
          data?.getWritingCircleData.imageDownloadURL ||
          "";
  }, [selectedPresetID, objectURL, presetData, data]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length !== 1) return;

      const reader = new FileReader();
      reader.onload = async () => {
        const binaryStr = reader.result as ArrayBuffer;
        const response = await getProfilePhotoUploadLink();

        const form = new FormData();
        Object.entries(response.data.getImageUploadLink.fields).forEach(
          ([field, value]) => {
            form.append(field, value as string);
          }
        );
        form.append("file", new Blob([new Uint8Array(binaryStr)]));

        const fetchResult = await fetch(response.data.getImageUploadLink.link, {
          method: "POST",
          body: form,
        });

        if (fetchResult.ok) {
          const photoResponse = await setImageUploaded({
            variables: {
              imageID: response.data.getImageUploadLink.imageID,
            },
          });
          if (photoResponse.data?.setImageUploaded.photoDownloadURL) {
            await updateSettings({
              variables: {
                circleID,
                name: title,
                description,
                profilePhotoURL:
                  photoResponse.data.setImageUploaded.photoDownloadURL,
              },
            });
          }
        }
      };
      reader.readAsArrayBuffer(acceptedFiles[0]);
    },
    [
      getProfilePhotoUploadLink,
      setImageUploaded,
      title,
      description,
      circleID,
      updateSettings,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 1024 * 1024 * 5,
  });

  if (loading) return <div>Loading...</div>;

  return (
    <div className="flex flex-col w-full h-full">
      <PlaintextTitleHeader title={data?.getWritingCircleData.name || ""} />
      <div className="flex flex-col flex-1 items-center">
        <div
          className="font-sans"
          style={{
            marginTop: 20,
            width: 400,
            maxWidth: "100%",
          }}
        >
          <div style={{ marginBottom: 20 }}>
            <Button
              size="sm"
              startContent={<ArrowLeft />}
              onClick={() => {
                navigate(`/circle/${circleID}`);
              }}
            >
              Back to {data?.getWritingCircleData.name}
            </Button>
          </div>
          {isAdmin && (
            <>
              <div
                style={{ marginBottom: 20, fontSize: 20, fontWeight: "bold" }}
              >
                <p>Writing Circle Settings</p>
              </div>
              <div
                className="setting-row flex flex-row"
                style={{ marginBottom: 20 }}
              >
                <div
                  style={{
                    backgroundImage: `url(${selectedImageURL})`,
                    backgroundSize: "cover",
                    minWidth: 60,
                    minHeight: 60,
                    borderRadius: 10,
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                  onClick={() => setImageSelectorOpen(true)}
                  className="cursor-pointer"
                />
                <div
                  className="flex flex-col"
                  style={{ marginLeft: 10, width: "100%" }}
                >
                  <div style={{ marginBottom: 6 }}>
                    <p style={{ fontSize: 14 }}>Title</p>
                  </div>

                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    size="sm"
                    style={{ fontSize: 14 }}
                    classNames={{
                      inputWrapper: ["h-[24px]"],
                    }}
                    className="w-full"
                  />
                </div>
              </div>

              <hr
                className="border-t border-gray-200"
                style={{ margin: "20px 0" }}
              />

              <div className="setting-row" style={{ marginBottom: 20 }}>
                <div className="flex items-center w-full">
                  <Textarea
                    value={description}
                    style={{ fontSize: 14 }}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Enter description"
                    className="flex-1"
                    minRows={3}
                    maxRows={5}
                  />
                </div>
              </div>

              <div className="flex justify-end" style={{ marginTop: 40 }}>
                <Button
                  color="primary"
                  size="sm"
                  style={{ height: 34, fontSize: 14 }}
                  onClick={async () => {
                    if (selectedPresetID === "custom" && selectedFile) {
                      const reader = new FileReader();
                      reader.readAsArrayBuffer(selectedFile);
                      reader.onload = async () => {
                        const form = new FormData();
                        const binaryStr = reader.result as ArrayBuffer;
                        const response = await getProfilePhotoUploadLink();

                        Object.entries(
                          response.data.getImageUploadLink.fields
                        ).forEach(([field, value]) => {
                          form.append(field, value as string);
                        });
                        form.append(
                          "file",
                          new Blob([new Uint8Array(binaryStr)])
                        );

                        const fetchResult = await fetch(
                          response.data.getImageUploadLink.link,
                          {
                            method: "POST",
                            body: form,
                          }
                        );

                        if (fetchResult.ok) {
                          await updateSettings({
                            variables: {
                              circleID,
                              name: title,
                              description,
                              profileImageParameters: {
                                imageID:
                                  response.data.getImageUploadLink.imageID,
                                bucket:
                                  response.data.getImageUploadLink.fields
                                    .bucket,
                                key: response.data.getImageUploadLink.fields
                                  .key,
                              },
                            },
                          });
                        }
                      };
                    } else {
                      await updateSettings({
                        variables: {
                          circleID,
                          name: title,
                          description,
                          presetID: selectedPresetID || undefined,
                        },
                      });
                    }
                  }}
                >
                  Save changes
                </Button>
              </div>
            </>
          )}
          <div style={{ marginBottom: 20, fontSize: 20, fontWeight: "bold" }}>
            <p>Personal Settings</p>
            <div>
              <Switch
                isSelected={notifyOnPost}
                onValueChange={async () => {
                  await updatePersonalSettings({
                    variables: {
                      circleID,
                      notifyOnPost: !notifyOnPost,
                    },
                  });
                  setNotifyOnPost(!notifyOnPost);
                }}
              >
                <p className="font-sans" style={{ fontSize: 14 }}>
                  Notify for new posts
                </p>
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={imageSelectorOpen}
        onClose={() => setImageSelectorOpen(false)}
        size="2xl"
        style={{
          maxHeight: "60vh",
          overflowY: "auto",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader
                className="font-sans text-lg font-bold"
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                  padding: "16px",
                  borderBottom: "1px solid #e5e5e5",
                }}
              >
                Select profile image
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "16px",
                    padding: "16px",
                  }}
                >
                  {presetData?.getRoomImagePresets.map(
                    (preset: { presetID: string; imageURL: string }) => (
                      <div
                        key={preset.presetID}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedPresetID(preset.presetID);
                          setImageSelectorOpen(false);
                        }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${preset.imageURL})`,
                            backgroundSize: "cover",
                            width: "100%",
                            paddingBottom: "100%",
                            borderRadius: "8px",
                            transition: "opacity 0.2s",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.opacity = "0.8")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.opacity = "1")
                          }
                        />
                      </div>
                    )
                  )}
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      paddingTop: "100%",
                      borderRadius: "8px",
                      border: "2px dashed #ccc",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="font-sans"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 2,
                        backgroundImage: `url(${objectURL})`,
                        backgroundSize: "cover",
                        cursor: objectURL ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (objectURL) {
                          setSelectedPresetID("custom");
                          setImageSelectorOpen(false);
                        }
                      }}
                    >
                      <div
                        className="flex flex-col items-center"
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.9)",
                          boxShadow:
                            "0px 0px 25px 25px rgba(255, 255, 255, 0.9)",
                        }}
                      >
                        <Button
                          size="sm"
                          variant="bordered"
                          onClick={() => {
                            fileInputRef.current?.click();
                          }}
                        >
                          Choose File
                        </Button>
                        <span style={{ marginTop: "8px", fontSize: "12px" }}>
                          {selectedFile ? selectedFile.name : "No file chosen"}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "#f0f0f0",
                        zIndex: 1,
                      }}
                    />
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          setSelectedFile(file);
                          setSelectedPresetID("custom");
                          setImageSelectorOpen(false);
                        }
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}
