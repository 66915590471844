import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button } from '@nextui-org/react';
import { Copy, CopySuccess } from 'iconsax-react';

const GET_WRITING_CIRCLE_INVITE_LINK = gql`
  query GetWritingCircleInviteLink($request: GetWritingCircleInviteLinkRequest!) {
    getWritingCircleInviteLink(request: $request)
  }
`;

interface InviteLinkButtonProps {
  circleID: string;
}

export function InviteLinkButton({ circleID }: InviteLinkButtonProps) {
  const [inviteLink, setInviteLink] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);

  const { refetch } = useQuery(GET_WRITING_CIRCLE_INVITE_LINK, {
    variables: {
      request: {
        circleID,
      },
    },
    skip: true,
  });

  const handleGetInviteLink = async () => {
    try {
      const { data } = await refetch();
      const inviteCode = data.getWritingCircleInviteLink;
      const link = `${window.location.origin}/circle/${circleID}?inviteCode=${inviteCode}`;
      setInviteLink(link);
    } catch (error) {
      console.error('Error fetching invite link:', error);
    }
  };

  const handleCopy = async () => {
    if (inviteLink) {
      await navigator.clipboard.writeText(inviteLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }
  };

  return (
    <div className="space-y-2">
      <Button 
        variant="bordered" 
        onClick={handleGetInviteLink}
        className="w-full"
      >
        Get Invite Link
      </Button>
      
      {inviteLink && (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '8px', 
          padding: '8px', 
          backgroundColor: '#f3f4f6', 
          borderRadius: '6px' 
        }}>
          <code style={{ 
            fontSize: '14px', 
            flexGrow: 1, 
            wordBreak: 'break-all' 
          }}>
            {inviteLink}
          </code>
          <Button
            variant="ghost"
            size="sm"
            onClick={handleCopy}
            style={{ flexShrink: 0 }}
          >
            {copied ? (
              <CopySuccess className="h-4 w-4 text-green-500" />
            ) : (
              <Copy className="h-4 w-4" />
            )}
          </Button>
        </div>
      )}
    </div>
  );
} 