import React, { useState, useRef, useEffect } from 'react';

interface TooltipProps {
  text: string;
  active?: boolean;
  children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ text, active = true, children }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [rect, setRect] = useState({ top: 0, left: 0, width: 0 });
  const childRef = useRef<HTMLDivElement>(null);

  const containerStyle: React.CSSProperties = {
    position: 'relative',
    display: 'inline-block',
  };

  const tooltipStyle: React.CSSProperties = {
    visibility: active && isHovered ? 'visible' : 'hidden',
    position: 'fixed',
    bottom: `${window.innerHeight - rect.top + 8}px`,
    left: `${rect.left + rect.width / 2}px`,
    transform: 'translateX(-50%)',
    padding: '8px',
    backgroundColor: '#333',
    color: 'white',
    borderRadius: '4px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    zIndex: 10000,
  };

  const arrowStyle: React.CSSProperties = {
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: '#333 transparent transparent transparent',
  };

  useEffect(() => {
    if (childRef.current && isHovered) {
      const r = childRef.current.getBoundingClientRect();
      setRect(r);
    }
  }, [isHovered]);

  return (
    <div
      ref={childRef}
      style={containerStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      <div style={tooltipStyle}>
        {text}
        <div style={arrowStyle} />
      </div>
    </div>
  );
};

