import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useMediaQuery } from "react-responsive";
import HomepageHeader from "../components/HomepageHeader";
import { useUserInfo } from "../hooks/useUserInfo";
import ProfileAvatar from "../components/ProfileAvatar";
import { Textarea, Button } from "@nextui-org/react";
import { useState, memo, useCallback, useRef, useEffect, useMemo } from "react";
import { ArrowRight } from "iconsax-react";
import { formatDistanceToNow, pluralize } from "../utils/string";
import { GroupWritingSession } from "../gql/graphql";
import WordAttributionBar from "../components/WordAttributionBar";
import { InviteLinkButton } from "../components/InviteLinkButton";
import { Link } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

const GET_WRITING_CIRCLE_DATA = gql`
  query GetWritingCircleData($circleID: String!) {
    getWritingCircleData(request: { circleID: $circleID }) {
      circleID
      name
      description
      imageDownloadURL
      associatedRoomID
      members {
        username
        profilePhotoURL
        profilePlaceholder
      }
      groupWritingSessions {
        createdAt
        endedAt
        userWordsWritten {
          userID
          wordsWritten
        }
        associatedUsers {
          userID
          username
          profilePhotoURL
          profilePlaceholder
        }
      }
      isAdmin
      isMember
    }
  }
`;

const GET_POSTS = gql`
  query GetPosts($circleID: String!, $postID: String) {
    getPostsInWritingCircle(request: { circleID: $circleID, postID: $postID }) {
      postID
      createdAt
      textContent
      username
      profilePhotoURL
      profilePlaceholder
      likes
      comments {
        commentID
        createdAt
        textContent
        username
        profilePhotoURL
        profilePlaceholder
        likes
        replies {
          commentID
          createdAt
          textContent
          username
          profilePhotoURL
          profilePlaceholder
          likes
        }
      }
    }
  }
`;

const CREATE_POST = gql`
  mutation CreatePost($circleID: String!, $post: String!) {
    createPostInWritingCircle(request: { circleID: $circleID, post: $post }) {
      resourceID
    }
  }
`;

const TOGGLE_LIKE_POST = gql`
  mutation ToggleLikePost($postID: String!) {
    toggleLikePostInWritingCircle(request: { postID: $postID }) {
      success
    }
  }
`;

const TOGGLE_LIKE_COMMENT = gql`
  mutation ToggleLikeComment($commentID: String!) {
    toggleLikeCommentInWritingCircle(request: { commentID: $commentID }) {
      success
    }
  }
`;

const COMMENT_ON_POST = gql`
  mutation CommentOnPost(
    $postID: String!
    $comment: String!
    $replyToCommentID: String
  ) {
    commentOnPostInWritingCircle(
      request: {
        postID: $postID
        comment: $comment
        replyToCommentID: $replyToCommentID
      }
    ) {
      success
    }
  }
`;

const VERIFY_CIRCLE_ACCESS = gql`
  query VerifyCircleAccess($circleID: String!, $inviteCode: String) {
    accessWritingCircle(
      request: { circleID: $circleID, inviteCode: $inviteCode }
    ) {
      success
    }
  }
`;

const CreatePostBox = memo(function CreatePostBox({
  postContent,
  setPostContent,
  handlePost,
  profilePhotoDownloadURL,
  profilePlaceholder,
}: {
  postContent: string;
  setPostContent: (value: string) => void;
  handlePost: () => void;
  profilePhotoDownloadURL: string | null;
  profilePlaceholder: string;
}) {
  return (
    <div
      className="flex flex-row w-full gap-3 p-4 border-b"
      style={{ paddingTop: 0 }}
    >
      <ProfileAvatar
        src={profilePhotoDownloadURL ?? ""}
        profilePlaceholder={profilePlaceholder}
        style={{ width: 48, height: 48 }}
      />
      <div className="flex-1">
        <Textarea
          placeholder="What's on your mind?"
          minRows={2}
          variant="bordered"
          className="w-full"
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
        />
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}
        >
          <Button color="primary" onClick={handlePost}>
            Post
          </Button>
        </div>
      </div>
    </div>
  );
});

// Add interface for member type
interface CircleMember {
  userID: string;
  username: string;
  profilePhotoURL: string | null;
  profilePlaceholder: string;
}

// Add new component for session visualization
const WritingSessionCard = memo(function WritingSessionCard({
  session,
}: {
  session: {
    createdAt: string;
    endedAt?: string | null;
    userWordsWritten: { userID: string; wordsWritten: number }[];
    associatedUsers: {
      userID: string;
      username: string;
      profilePhotoURL?: string | null;
      profilePlaceholder: string;
    }[];
  };
}) {
  // Create username color map from profile placeholders
  const usernameColorMap = session.associatedUsers.reduce((acc, user) => {
    // Extract hex code from profilePlaceholder (format: hexCode||number)
    const hexCode = user.profilePlaceholder.split("|")[0];
    return {
      ...acc,
      [user.username]: hexCode,
    };
  }, {} as { [key: string]: string });

  // Calculate session duration
  const startTime = new Date(session.createdAt);
  const endTime = session.endedAt ? new Date(session.endedAt) : new Date();
  const durationInMinutes = Math.round(
    (endTime.getTime() - startTime.getTime()) / (1000 * 60)
  );

  // Coalesce and sort users by total words written
  const coalescedUsers = session.userWordsWritten
    .reduce((acc, curr) => {
      const user = session.associatedUsers.find(
        (u) => u.userID === curr.userID
      );
      if (!user) return acc;

      const existing = acc.find((item) => item.user.userID === curr.userID);
      if (existing) {
        existing.wordsWritten += curr.wordsWritten;
      } else {
        acc.push({ user, wordsWritten: curr.wordsWritten });
      }
      return acc;
    }, [] as { user: (typeof session.associatedUsers)[0]; wordsWritten: number }[])
    .sort((a, b) => b.wordsWritten - a.wordsWritten);

  const totalWords = coalescedUsers.reduce(
    (sum, { wordsWritten }) => sum + wordsWritten,
    0
  );

  // Generate dynamic title
  const generateTitle = () => {
    if (!session.endedAt) {
      return "Writing in progress...";
    }

    const usernames = coalescedUsers.map((u) => u.user.username);
    let title = "";

    if (usernames.length === 1) {
      title = `${usernames[0]} wrote ${totalWords} ${pluralize({
        count: totalWords,
        word: "word",
      })}`;
    } else {
      const lastUser = usernames.pop();
      title = `${usernames.join(
        ", "
      )} and ${lastUser} wrote ${totalWords} ${pluralize({
        count: totalWords,
        word: "word",
      })} together`;
    }

    return `${title} in ${durationInMinutes} ${pluralize({
      count: durationInMinutes,
      word: "minute",
    })}.`;
  };

  // Generate word attribution data (showing individual contributions)
  const wordAttributionList = session.userWordsWritten.flatMap(
    ({ userID, wordsWritten }) => {
      const user = session.associatedUsers.find((u) => u.userID === userID);
      return user ? Array(wordsWritten).fill(user.username) : [];
    }
  );

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mb-4 border">
      <p
        style={{
          fontSize: "0.75rem",
          color: "#6B7280",
          marginBottom: "8px",
        }}
      >
        {formatDistanceToNow(startTime)} ago
      </p>
      <p className="font-sans font-semibold mb-3">{generateTitle()}</p>

      {/* Coalesced user contribution list */}
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
      >
        {coalescedUsers.map(({ user, wordsWritten }) => (
          <div
            key={user.userID}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            <ProfileAvatar
              src={user.profilePhotoURL ?? ""}
              profilePlaceholder={user.profilePlaceholder}
              style={{ width: 24, height: 24 }}
            />
            <span className="text-sm font-sans text-left whitespace-nowrap">
              {user.username} ({wordsWritten})
            </span>
          </div>
        ))}
      </div>

      {/* Word attribution visualization */}
      <WordAttributionBar
        usernameWordList={wordAttributionList}
        usernameColorMap={usernameColorMap}
        allowHover={false}
      />
    </div>
  );
});

// 1. Memoize the Post component
const PostComponent = memo(function Post({
  post,
  expandedComments,
  setExpandedComments,
  replyToComment,
  setReplyToComment,
  showComments,
  setShowComments,
}: {
  post: any;
  expandedComments: { [key: string]: boolean };
  setExpandedComments: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  replyToComment: string | null;
  setReplyToComment: (commentId: string | null) => void;
  showComments: boolean;
  setShowComments: (visible: boolean) => void;
}) {
  const [commentContent, setCommentContent] = useState<{
    [key: string]: string;
  }>({
    main: "",
  });

  const replyInputRef = useRef<HTMLTextAreaElement>(null);

  const [commentOnPost] = useMutation(COMMENT_ON_POST, {
    refetchQueries: [GET_POSTS],
  });

  const [toggleLikePost] = useMutation(TOGGLE_LIKE_POST, {
    refetchQueries: [GET_POSTS],
  });

  const handleLikePost = useCallback(
    async (postID: string) => {
      try {
        await toggleLikePost({ variables: { postID } });
      } catch (error) {
        // Error handling removed
      }
    },
    [toggleLikePost]
  );

  useEffect(() => {
    if (replyToComment && replyInputRef.current) {
      replyInputRef.current.focus();
    }
  }, [replyToComment]);

  const handleComment = useCallback(
    async (postID: string, inputKey: string) => {
      const content = commentContent[inputKey];
      if (!content?.trim()) return;

      try {
        await commentOnPost({
          variables: {
            postID,
            comment: content,
            replyToCommentID: inputKey === "main" ? null : inputKey,
          },
        });

        setCommentContent((prev) => ({
          ...prev,
          [inputKey]: "",
        }));
        setReplyToComment(null);
      } catch (error) {
        // Error handling removed
      }
    },
    [commentContent, commentOnPost, setReplyToComment]
  );

  const [toggleLikeComment] = useMutation(TOGGLE_LIKE_COMMENT, {
    refetchQueries: [GET_POSTS],
  });

  const handleLikeComment = useCallback(
    async (commentID: string) => {
      try {
        await toggleLikeComment({ variables: { commentID } });
      } catch (error) {
        // Error handling removed
      }
    },
    [toggleLikeComment]
  );

  const handleReplyClick = useCallback(
    (commentID: string) => {
      setReplyToComment(commentID);
      setCommentContent((prev) => ({
        ...prev,
        [commentID]: "",
      }));
      setExpandedComments((prev) => ({
        ...prev,
        [commentID]: true,
      }));
    },
    [setExpandedComments, setReplyToComment]
  );

  return (
    <div className="flex flex-col w-full gap-3 p-4 border-b">
      <div className="flex flex-row gap-3">
        <ProfileAvatar
          src={post.profilePhotoURL ?? ""}
          profilePlaceholder={post.profilePlaceholder}
          style={{ width: 48, height: 48 }}
        />
        <div className="flex-1">
          <div className="flex flex-row items-center gap-2">
            <span className="font-semibold">{post.username}</span>
            <span className="text-gray-500 text-sm">
              {formatDistanceToNow(new Date(post.createdAt))} ago
            </span>
          </div>
          <p className="mt-2">{post.textContent}</p>
          <div className="flex flex-row gap-4 mt-2 text-gray-500 text-sm">
            <button
              onClick={() => handleLikePost(post.postID)}
              className={`flex items-center gap-1 ${
                post.likes.includes(post.username) ? "text-blue-500" : ""
              }`}
            >
              <span>
                {post.likes.length === 1
                  ? "1 like"
                  : `${post.likes.length} likes`}
              </span>
            </button>
            <button onClick={() => setShowComments(!showComments)}>
              {showComments
                ? "Hide comments"
                : post.comments.length === 0
                ? "Comment"
                : post.comments.length === 1
                ? "Show 1 comment"
                : `Show ${post.comments.length} comments`}
            </button>
          </div>
        </div>
      </div>

      {showComments && (
        <>
          <div style={{ marginTop: 8, marginLeft: 24 }}>
            <Textarea
              placeholder="Write a comment..."
              minRows={1}
              value={commentContent.main}
              onChange={(e) =>
                setCommentContent((prev) => ({
                  ...prev,
                  main: e.target.value,
                }))
              }
              className="w-full"
            />
            <div className="flex justify-end items-center mt-2">
              <Button
                size="sm"
                color="primary"
                onClick={() => handleComment(post.postID, "main")}
              >
                Comment
              </Button>
            </div>
          </div>

          <div style={{ marginTop: 8 }}>
            {post.comments.map((comment: any) => (
              <div
                key={comment.commentID}
                style={{
                  marginLeft: 48,
                  borderLeft: "2px solid #e5e7eb",
                  paddingLeft: 16,
                  marginBottom: 12,
                }}
              >
                <div className="flex items-start gap-2">
                  <ProfileAvatar
                    src={comment.profilePhotoURL ?? ""}
                    profilePlaceholder={comment.profilePlaceholder}
                    style={{ width: 32, height: 32 }}
                  />
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <span className="font-semibold">{comment.username}</span>
                      <span className="text-gray-500 text-sm">
                        {formatDistanceToNow(new Date(comment.createdAt))} ago
                      </span>
                    </div>
                    <p style={{ marginTop: 4, fontSize: 14 }}>
                      {comment.textContent}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        gap: 16,
                        marginTop: 4,
                        fontSize: 12,
                      }}
                    >
                      <button
                        onClick={() => handleLikeComment(comment.commentID)}
                        style={{
                          color: comment.likes.includes(comment.username)
                            ? "#3B82F6"
                            : "#6B7280",
                        }}
                      >
                        {comment.likes.length === 1
                          ? "1 like"
                          : `${comment.likes.length} likes`}
                      </button>
                      {comment.replies?.length > 0 && (
                        <button
                          onClick={() =>
                            setExpandedComments((prev) => ({
                              ...prev,
                              [comment.commentID]: !prev[comment.commentID],
                            }))
                          }
                          className="text-gray-500"
                        >
                          {!expandedComments[comment.commentID]
                            ? `Show ${comment.replies.length} ${
                                comment.replies.length === 1
                                  ? "reply"
                                  : "replies"
                              }`
                            : "Hide replies"}
                        </button>
                      )}
                      <button
                        onClick={() => {
                          handleReplyClick(comment.commentID);
                        }}
                        className="text-gray-500"
                      >
                        Reply
                      </button>
                    </div>

                    {replyToComment === comment.commentID && (
                      <div className="mt-2">
                        <Textarea
                          ref={replyInputRef}
                          placeholder="Write a reply..."
                          minRows={1}
                          value={commentContent[comment.commentID] || ""}
                          onChange={(e) =>
                            setCommentContent((prev) => ({
                              ...prev,
                              [comment.commentID]: e.target.value,
                            }))
                          }
                          className="w-full"
                        />
                        <div className="flex justify-end gap-2 mt-2">
                          <Button
                            size="sm"
                            variant="light"
                            onClick={() => {
                              setReplyToComment(null);
                              setCommentContent((prev) => ({
                                ...prev,
                                [comment.commentID]: "",
                              }));
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() =>
                              handleComment(post.postID, comment.commentID)
                            }
                          >
                            Reply
                          </Button>
                        </div>
                      </div>
                    )}

                    {expandedComments[comment.commentID] && (
                      <>
                        {comment.replies?.map((reply: any) => (
                          <div
                            key={reply.commentID}
                            style={{
                              marginLeft: 32,
                              marginTop: 8,
                              borderLeft: "2px solid #e5e7eb",
                              paddingLeft: 16,
                              marginBottom: 12,
                            }}
                          >
                            <div className="flex items-start gap-2">
                              <ProfileAvatar
                                src={reply.profilePhotoURL ?? ""}
                                profilePlaceholder={reply.profilePlaceholder}
                                style={{ width: 24, height: 24 }}
                              />
                              <div>
                                <div className="flex items-center gap-2">
                                  <span className="font-semibold">
                                    {reply.username}
                                  </span>
                                  <span className="text-gray-500 text-sm">
                                    {formatDistanceToNow(
                                      new Date(reply.createdAt)
                                    )}{" "}
                                    ago
                                  </span>
                                </div>
                                <p style={{ marginTop: 4, fontSize: 14 }}>
                                  {reply.textContent}
                                </p>
                                <button
                                  onClick={() =>
                                    handleLikeComment(reply.commentID)
                                  }
                                  style={{
                                    marginTop: 4,
                                    fontSize: 12,
                                    color: reply.likes.includes(reply.username)
                                      ? "#3B82F6"
                                      : "#6B7280",
                                  }}
                                >
                                  {reply.likes.length === 1
                                    ? "1 like"
                                    : `${reply.likes.length} likes`}
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
});

// 2. Memoize the members list
const MembersList = memo(function MembersList({
  members,
  onMemberClick,
}: {
  members: CircleMember[];
  onMemberClick: (username: string) => void;
}) {
  return (
    <div className="flex flex-row flex-wrap gap-2">
      {members.map((member) => (
        <div
          key={member.username}
          style={{ position: "relative", cursor: "pointer" }}
          onClick={() => onMemberClick(member.username)}
          onMouseEnter={(e) => {
            const tooltip = e.currentTarget.querySelector(
              ".tooltip"
            ) as HTMLElement;
            if (tooltip) tooltip.style.visibility = "visible";
          }}
          onMouseLeave={(e) => {
            const tooltip = e.currentTarget.querySelector(
              ".tooltip"
            ) as HTMLElement;
            if (tooltip) tooltip.style.visibility = "hidden";
          }}
        >
          <ProfileAvatar
            src={member.profilePhotoURL ?? ""}
            profilePlaceholder={member.profilePlaceholder}
            style={{ width: 40, height: 40 }}
          />
          <div
            className="tooltip"
            style={{
              position: "absolute",
              visibility: "hidden",
              backgroundColor: "#1F2937",
              color: "white",
              fontSize: "0.875rem",
              padding: "4px 8px",
              borderRadius: "4px",
              bottom: "-32px",
              left: "50%",
              transform: "translateX(-50%)",
              whiteSpace: "nowrap",
              zIndex: 50,
            }}
          >
            {member.username}
          </div>
        </div>
      ))}
    </div>
  );
});

export default function WritingCirclePage() {
  const { circleID, postID } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteCode = useMemo(
    () => searchParams.get("inviteCode"),
    [searchParams]
  );

  const isMobile = useMediaQuery({
    query: "(max-width: 1060px)",
  });

  const { data, loading } = useQuery(GET_WRITING_CIRCLE_DATA, {
    variables: { circleID },
    fetchPolicy: "no-cache",
  });

  const { data: postsData } = useQuery(GET_POSTS, {
    variables: { circleID, postID },
    fetchPolicy: "no-cache",
  });

  const isPostPage = useMemo(() => !!postID, [postID]);

  const { profilePhotoDownloadURL, profilePlaceholder } = useUserInfo();

  const [postContent, setPostContent] = useState("");
  const [replyToComment, setReplyToComment] = useState<string | null>(null);
  const [expandedComments, setExpandedComments] = useState<{
    [key: string]: boolean;
  }>({});
  const [createPost] = useMutation(CREATE_POST, {
    refetchQueries: [GET_POSTS],
    variables: { circleID },
  });

  const handlePost = async () => {
    if (!postContent.trim()) return;

    try {
      await createPost({
        variables: {
          circleID,
          post: postContent,
        },
      });
      setPostContent(""); // Clear the input after successful post
    } catch (error) {
      // Error handling removed
    }
  };

  function MobileHeader() {
    return (
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 20,
        }}
      >
        <div className="flex flex-row items-center">
          <div
            style={{
              width: 100,
              height: 100,
              marginRight: 20,
              borderRadius: 10,
              backgroundImage: `url(${data?.getWritingCircleData.imageDownloadURL})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <div>
            <div className="mt-2">
              <p
                className="font-sans"
                style={{ fontSize: 20, fontWeight: "bold" }}
              >
                {data?.getWritingCircleData.name}
              </p>
            </div>
            {data?.getWritingCircleData.description && (
              <div className="mt-2">
                <p className="font-sans" style={{ fontSize: 14 }}>
                  {data.getWritingCircleData.description}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  // 3. Memoize the navigation callback
  const handleMemberClick = useCallback(
    (username: string) => {
      navigate(`/writer/${username}`);
    },
    [navigate]
  );

  // Add showComments state at the parent level
  const [visibleComments, setVisibleComments] = useState<{
    [key: string]: boolean;
  }>({});

  // Update verification to use query instead of mutation
  const { data: accessData } = useQuery(VERIFY_CIRCLE_ACCESS, {
    variables: {
      circleID,
      inviteCode: inviteCode || undefined,
    },
    onError: () => {
      navigate("/");
    },
  });

  // Add effect to check access data
  useEffect(() => {
    if (accessData && !accessData.accessWritingCircle.success) {
      navigate("/");
    }
  }, [accessData, navigate]);

  if (loading) {
    return null;
  }

  return (
    <div>
      <HomepageHeader />
      {isMobile ? (
        <div className="flex flex-col w-full">
          <MobileHeader />
          <CreatePostBox
            postContent={postContent}
            setPostContent={setPostContent}
            handlePost={handlePost}
            profilePhotoDownloadURL={profilePhotoDownloadURL ?? null}
            profilePlaceholder={profilePlaceholder}
          />
          {/* Mobile posts feed will go here */}
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center relative">
          {/* Left column - Circle info */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                margin: 20,
                marginTop: 30,
                marginRight: 64,
                width: 200,
                marginLeft: 64,
              }}
            >
              <div
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 10,
                  backgroundImage: `url(${data?.getWritingCircleData.imageDownloadURL})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              <div style={{ marginTop: 16 }}>
                <p
                  className="font-sans"
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  {data?.getWritingCircleData.name}
                </p>
              </div>
              {data?.getWritingCircleData.description && (
                <div style={{ marginTop: 16 }}>
                  <p className="font-sans" style={{ fontSize: 14 }}>
                    {data.getWritingCircleData.description}
                  </p>
                </div>
              )}
              <div style={{ marginTop: 16 }}>
                <InviteLinkButton circleID={circleID!} />
              </div>
              <div style={{ marginTop: 10 }}>
                <Button
                  as={Link}
                  to={`/circle/${circleID}/settings`}
                  color="primary"
                  variant="flat"
                  className="w-full"
                >
                  Circle Settings
                </Button>
              </div>
              {/* Members section */}
              <div style={{ marginTop: 32 }}>
                <p
                  className="font-sans"
                  style={{ fontSize: 16, fontWeight: 600, marginBottom: 16 }}
                >
                  Members ({data?.getWritingCircleData.members.length})
                </p>
                <MembersList
                  members={data?.getWritingCircleData.members ?? []}
                  onMemberClick={handleMemberClick}
                />
              </div>
            </div>
          </div>

          {/* Middle column - Posts */}
          <div
            className="flex flex-col flex-1 items-start font-sans"
            style={{ maxWidth: 500, margin: "0 auto" }}
          >
            <div
              className="flex flex-col items-start"
              style={{ width: "100%", marginTop: 30 }}
            >
              {isPostPage ? (
                <Button onClick={() => navigate(`/circle/${circleID}`)}>
                  <div style={{ marginRight: 16 }}>
                    <ArrowLeft size={20} />
                  </div>
                  <div>
                    <p className="font-sans" style={{ fontSize: 16 }}>
                      See all posts
                    </p>
                  </div>
                </Button>
              ) : (
                <CreatePostBox
                  postContent={postContent}
                  setPostContent={setPostContent}
                  handlePost={handlePost}
                  profilePhotoDownloadURL={profilePhotoDownloadURL ?? null}
                  profilePlaceholder={profilePlaceholder}
                />
              )}
              {postsData?.getPostsInWritingCircle.map((post: any) => (
                <PostComponent
                  key={post.postID}
                  post={post}
                  expandedComments={expandedComments}
                  setExpandedComments={setExpandedComments}
                  replyToComment={replyToComment}
                  setReplyToComment={setReplyToComment}
                  showComments={visibleComments[post.postID]}
                  setShowComments={(visible: boolean) =>
                    setVisibleComments((prev) => ({
                      ...prev,
                      [post.postID]: visible,
                    }))
                  }
                />
              ))}
            </div>
          </div>

          {/* Right column - Writing activity */}
          <div style={{ width: 300, marginRight: 64, marginTop: 30 }}>
            <Button
              color="primary"
              endContent={<ArrowRight size={20} />}
              className="font-sans w-full"
              style={{ marginBottom: 32 }}
              onClick={() => {
                navigate(
                  `/room/${data?.getWritingCircleData.associatedRoomID}`
                );
              }}
            >
              GO WRITE
            </Button>
            <div
              className="flex flex-row justify-between w-full"
              style={{ marginBottom: 24 }}
            >
              <p className="font-sans font-semibold" style={{ fontSize: 20 }}>
                Writing Sessions
              </p>
            </div>
            <div className="space-y-6 max-h-[calc(100vh-200px)] overflow-y-auto">
              {data?.getWritingCircleData.groupWritingSessions?.map(
                (session: GroupWritingSession) => (
                  <WritingSessionCard
                    key={session.createdAt}
                    session={session}
                  />
                )
              )}
              {(!data?.getWritingCircleData.groupWritingSessions ||
                data.getWritingCircleData.groupWritingSessions.length ===
                  0) && (
                <div className="flex flex-col items-center justify-center text-center text-gray-500">
                  <p className="font-sans text-sm" style={{ color: "gray" }}>
                    No writing sessions yet. Click "GO WRITE" to start one!
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
